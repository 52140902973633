<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">
				<h4 class="p-m-2">Existencias</h4>
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-6">
							<label for="producto">Producto a Consultar</label>
                            <div class="p-inputgroup">
                                <InputText size="100" id="codigo_consultar" type="text" v-model="filtroProducto" v-on:keyup.enter="buscarProducto"/>				
                                <Button icon="pi pi-search" @click="seleccionarProducto"/>							
                            </div>	
						</div>																												
					</div>	
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="ubicaciones" class="p-datatable-sm" :rowClass="rowClass" dataKey="id_ubicacion" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<template #header>
						<!-- <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">	 -->
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-6">
                                <label for="codigo">Código</label>
                                <InputText id="codig" v-model="selProducto.codigo" type="text" disabled/>
                            </div>    
                            <div class="p-field p-col-12 p-md-6">
                                <label for="unidad_medida">Unidad de Medida</label>
                                <InputText id="unidad_medida" v-model="selProducto.unidad_medida" type="text" disabled/>
                            </div>                                                
                            <div class="p-fluid p-col-12 p-mb-2">
                                <InputText id="producto" v-model="selProducto.producto" type="text" disabled/>
                            </div>    
                            <div class="p-fluid p-col-12 p-mb-2">
                                <InputText id="descripcion" v-model="selProducto.descripcion" type="text" disabled/>
                            </div>                                                    
                        </div>
                    
					</template>
					<Column field="id_sucursal" header="Sucursal" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.codigo_sucursal}}-{{slotProps.data.sucursal}}
						</template>
					</Column>                    
					<Column field="id_bodega" header="Bodega" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.codigo_bodega}}-{{slotProps.data.bodega}}
						</template>
					</Column>
					<Column field="id_ubicacion" header="Ubicación">
						<template #body="slotProps">
							{{slotProps.data.codigo_ubicacion}}
						</template>
					</Column>
					<Column field="existencia" header="Existencia" :sortable="true">
						<template #body="slotProps">
                            <div class="p-text-bold">
							{{formatCurrency(slotProps.data.existencia)}}
                            </div>
						</template>
					</Column>
                    <ColumnGroup type="footer">
                        <Row>				
                            <Column footer="Total Disponible:" :colspan="3" footerStyle="text-align:right"/>
                            <Column :footer="formatCurrency(existenciaTotal)"/>			
                        </Row>
                    </ColumnGroup>		                    
				</DataTable>

                <BuscarProducto ref="dlgBuscaProducto" :mostrar="productoDialog" :tipo="tipoProducto" :mostrar_conversiones="mostrarConversiones" @cancelar="cerrarDlgProducto"  @seleccionar="elegirProducto" :filtro="filtroProducto" :key="productoDlgRefresh"/>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import BuscarProducto from '../components/BuscarProducto.vue';
import InvService from '../service/inv.service';

export default {
	setup() {
		const rowClass = (data) => {
            return data.anulado === 'S' ? 'row-anulada': null;
        };

		return { rowClass }
	},	
	components: { Loading, BuscarProducto },
	data() {
		return {
            mostrarConversiones: "N",
            tipoProducto:"PRODU",
			productoDialog: false,
            selProducto: {codigo: '', producto: ''},
			productoDlgRefresh: 0,
			filtroProducto: '',
			loading: false,		
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},            
			ubicaciones: null,
            existenciaTotal: 0.0
		}
	},
	computed: 
	{
		mostrarBotonBusquedaCtaHabiente(){
			return (this.id_cta_habiente_info == '0')
		}
	},	
	methods: {
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},        
		buscarProducto()
		{
			// AppCtx, filtro, mostrarConversiones, tipoProducto, idLista, mostrarMateriaPrima
			InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroProducto, this.mostrarConversiones, this.tipoProducto, '', 'S').then(data => 
			{
				if(data.length == 1){
					data[0].mostrar = 'S';
                    this.selProducto = data[0];
					this.elegirProducto(this.selProducto);	
				} else {
					this.$refs.dlgBuscaProducto.mostrarResultadosBusqueda(this.filtroProducto, data);									
				}
				this.productoDialogLoading = false;
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
                this.selProducto = {codigo: '', producto: ''};
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
		},
		seleccionarProducto(){
			if(this.filtroProducto == '') {
				this.productoDialog = true;
				this.dlgBuscarProductoRefresh++;
			} else {
				this.buscarProducto();
			}
		},
		cerrarDlgProducto(){
			this.filtroProducto = '';
			this.productoDialog = false;
		},		
		elegirProducto(Producto){
			
			this.productoDialog = false;	
            this.selProducto = Producto;

			this.loading = true;
			InvService.getExistenciaProducto(this.$store.state.auth.currentAppCtx, Producto.id_producto_empresa).then(data => 
			{
				this.loading = false;
                this.ubicaciones = data;
                this.existenciaTotal = 0;

                if(this.ubicaciones.length > 0){
                    this.ubicaciones.forEach(element => this.existenciaTotal += element.existencia)
                }
			}	
			).catch(
				data => {
				this.productoDialogLoading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}				
			);				
    },
    }
}
</script>

<style scoped lang="scss">
.anulado {
  color: #bd2130;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin: auto;  
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}

::v-deep(.row-anulada) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>    

