<template>
		<Dialog header="Header" v-model:visible="showDlg" :style="{width: '50vw'}" :modal="true" @hide="hideDlg" :closable="true" :closeOnEscape="true">
			<template #header>				
				Buscar Producto
			</template>
				<div class="p-fluid p-field">
					<div class="p-inputgroup">
						<InputText id="filtroProducto" type="text" placeholder="Escriba el criterio de búsqueda" v-model="filtroBusquedaProducto"	@keyup.enter="buscarProducto" autofocus/>
						<Button icon="pi pi-search" @click="buscarProducto"/>
					</div>
				</div>				
				<div class="p-fluid p-field">
					<div v-if="lkpProductos.length > 0">
						<DataTable ref="resultados" :value="lkpProductos" :scrollable="true" scrollHeight="flex" :key="refreshLkpProductos" :loading="productoDialogLoading"
						v-model:selection="selProducto" selectionMode="single" dataKey="id_producto_conversion" row-select="seleccionarProducto">
						<Column field="codigo" header="Código" style="min-width:100px"></Column>
						<Column field="id_producto_conversion" header="Producto" style="min-width:300">
							<template #body="slotProps">
								<div v-tooltip.top="slotProps.data.descripcion_larga">
									{{ (appMode == 1 ? slotProps.data.descripcion_simple : slotProps.data.producto) }}
								</div>																
							</template>
						</Column>						
						<Column field="categoria" header="Categoría" style="min-width:100px"></Column>
						<Column field="clasificacion" header="Clasificación" style="min-width:100px"></Column>						
						</DataTable>				
					</div>
					<div v-else>
						<h5 style="margin: 15px auto; text-align: center;">No hay datos para mostrar</h5>
					</div>
				</div>			
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" @click="cancelarBuscarProducto" class="p-button-text"/>
				<!-- <Button label="Seleccionar" icon="pi pi-check" @click="elegirProducto" autofocus :disabled="productoSelected"/> -->
				<Button v-if="productoSelected" label="Seleccionar" icon="pi pi-check" @click="elegirProducto" autofocus />				
			</template>
		</Dialog>
</template>

<script>
import { CURRENT_APP_MODE } from '../service/constants';
	import InvService from '../service/inv.service';
	
	export default {
		name: 'BuscarProducto',
		props: {
			mostrar: {type: Boolean, required: true},
			id_lista: {type: String, required: false},
			filtro: {type: String, required: false},
			tipo: {type: String, required: false},
			mostrar_conversiones: {type: String, required: false},
			mostrar_materia_prima: {type: String, required: false}
		},
		data() {
		return {
			appMode: CURRENT_APP_MODE,
			selCtaHabienteInfo: this.IdCtaHabienteInfo,					
			showDlg: this.mostrar,
			idLista: this.id_lista,
			tipoProducto: this.tipo,
			mostrarConversiones: this.mostrar_conversiones,
			mostrarMateriaPrima: this.mostrar_materia_prima,
			selProducto: null,
			productoDialog: null,				
			productoDialogLoading: false,
			filtroBusquedaProducto: this.filtro,
			lkpProductos: [],
			refreshLkpProductos: 0
		}
		},
		computed: {
			productoSelected() {
				return this.selProducto;
			}		
		},
		methods: {
			hideDlg() {
				this.cancelarBuscarProducto();
			},	
			mostrarResultadosBusqueda(filtro, resultados){
				this.filtroBusquedaProducto = filtro;				
				this.lkpProductos = resultados;
				
				if(this.lkpProductos.length > 0) {
					this.refreshLkpProductos++;
				}	
				
				this.showDlg = true;
			},
			cancelarBuscarProducto() {
				this.showDlg = false;
				this.$emit('cancelar');				
			},
			seleccionarProducto(){
				if(this.lkpProductos.length == 1)
				{
					this.elegirProducto();
				}
			},
			elegirProducto() {				
				this.filtroBusquedaProducto = '';
				this.selProducto.mostrar = 'S';
				this.showDlg = false;
				this.$emit('seleccionar', this.selProducto);
				this.lkpProductos = [];
				this.selProducto = null;
			},
			buscarProducto() {
				this.productoDialogLoading = true;
				this.selProducto = null;
				
				var id_lista;
				
				if(!this.idLista){
					id_lista = ''
				} else {
					id_lista = this.idLista.trim();
				}
				
				var tipoProducto;
				if(!this.tipoProducto)
				{
					tipoProducto = ''
				} else {
					tipoProducto = this.tipoProducto.trim();
				}

				var mostrarConversiones;
				if(!this.mostrarConversiones) {
					mostrarConversiones = 'S'
				} else {
					mostrarConversiones = this.mostrarConversiones
				}

				var mostrarMateriaPrima;
				if(!this.mostrarMateriaPrima){
					mostrarMateriaPrima = 'S'
				} else {
					mostrarMateriaPrima = this.mostrarMateriaPrima
				}

				// AppCtx, filtro, mostrarConversiones, tipoProducto, idLista, mostrarMateriaPrima
				InvService.buscaProductos(this.$store.state.auth.currentAppCtx, this.filtroBusquedaProducto, mostrarConversiones, tipoProducto, id_lista, mostrarMateriaPrima).then(data => 
				{
					this.lkpProductos = data;
					this.productoDialogLoading = false;

					if(this.lkpProductos.length > 0) {
						// this.$refs["resultados"].focus();
					}					
				}	
				).catch(
					data => {
					this.productoDialogLoading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}				
				);		
			}			
		}
	}
</script>

<style scoped>

</style>
